<template>
<div class="">
  <custom-alert v-if="displayAlert==true" :message="alertMessage"
                v-on:showedAlert="resetAlert"  id="alert_box"></custom-alert>
    <div class="page-banner">
        <img :src="this.getSiteProps('clubinnerpages.headerbanner')" style="width:100%;" alt="page-banner">
         <div class="page-title">
            <h5 class="text-white">Driver Programs / MVR Review</h5>
         </div> 
    </div>
    <div class="row">
        <div class="col-md-8 mb-3 mt-4">
            <div class="card card-body pt-3 pb-3 driver-prog">             
               
<a href="#mvrsection" class="text-right text-blue">Skip to MVR Review Information</a>

<h5 class="mt-3 mb-3 text-danger" style="text-decoration:underline;">USA Water Ski &amp; Wake Sports Trained Boat Driver Course</h5>
<p>USA Water Ski &amp; Wake Sports Trained Boat Drivers are eligible to drive for USA Water Ski &amp;
Wake Sports-sanctioned GrassRoots events (GrassRoots tournaments and Basic Skills Clinics)
and club practices/exhibitions. This online course is the first step in acquiring a USA Water Ski &amp;
Wake Sports Trained Boat Driver rating and includes a WSIA Watercraft Safety Video, Boat US
Foundation’s Online Boating Safety Course and an on-water practical evaluation.  Note: Aspiring
NSSA (Show Ski) boat drivers should use the NSSA-specific course below.</p>

<ul class="listitem">
<li>
<h6 class="mt-3"><strong>Requirements</strong></h6>
<ul class="listitem">
    <li>Be at least 16 years of age and a current Active member of USA Water Ski &amp; Wake
Sports (Guest and Grassroots members are not eligible)</li>
    <li>Have a current, valid motor vehicle operator’s license (U.S. or Canada only)</li>
    <li>Comply with and meet all requirements for boater’s training and/or licensing under
state law applicable in the driver’s state of residence</li>   
<li>Motor Vehicle Record (MVR) Review - All aspiring USA Water Ski &amp; Wake Sports and
sport discipline rated drivers are required to submit to and successfully pass a Motor
Vehicle Record (MVR) Review to attain a drivers&#39; rating and again once every two years
thereafter to maintain their rating. It is recommended that you successfully complete
your review before beginning this course.</li>                         
</ul>
</li>
</ul>
<div class="row">
    <div class="col-md-6">
    <h4>Cost - $20.00</h4>
    </div>
    <div class="col-md-6">
        <a class="btn btn-primary mt-0" @click="openLink('Driverlogin_link')" :target="Driverlogin_link?'_blank':''" :href="Driverlogin_link?'https://usawaterski.digitalchalk.com/auth/login':'javascript:void(0)'">Purchase Trained Boat Driver Course</a>
    </div>
</div>

<hr>

<h5 class="mt-3 mb-3 text-danger" style="text-decoration:underline;"><strong>NSSA (Show Ski) Rated Driver Permit Course</strong></h5>
<p>This online course is the first step in acquiring an NSSA Class A Driver Learner’s Permit and includes a
driver safety training video and student manual, a written test consisting of 35 multiple choice or
true/false questions and an on-water practical evaluation.</p>

<ul class="listitem">
    <li>
    <h6 class="mt-3"><strong>Requirements</strong></h6>
        <ul class="listitem">
            <li>Be at least 18 years of age and a current Active member of USA Water Ski &amp; Wake
            Sports (Guest and GrassRoots members are not eligible)</li>
            <li>Have a current, valid motor vehicle operator’s license (U.S. or Canada only)</li>
            <li>Comply with and meet all requirements for boater’s training and/or licensing under
            state law applicable in the driver’s state of residence</li>     
            <li>Motor Vehicle Record (MVR) Review - All aspiring USA Water Ski &amp; Wake Sports and
            sport discipline rated drivers are required to submit to and successfully pass a Motor
            Vehicle Record (MVR) Review to attain a drivers&#39; rating and again once every two years
            thereafter to maintain their rating. It is recommended that you successfully complete
            your review before beginning this course.</li>                       
        </ul>
    </li>
</ul>
<div class="row">
    <div class="col-md-6">
    <h4>Cost - $20.00</h4>
    </div>
    <div class="col-md-6">
        <a class="btn btn-primary mt-0" @click="openLink('login_link')" :target="login_link?'_blank':''" :href="login_link?'https://usawaterski.digitalchalk.com/auth/login':'javascript:void(0)'">NSSA(Show Ski) Rated Driver Permit Course</a>
    </div>
</div>

<hr>
 <h5 id="mvrsection" class="text-danger" style="text-decoration:underline;">Motor Vehicle Record (MVR) Review</h5>
                                    <p class="mt-3">All aspiring USA-WSWS and sport discipline rated drivers are required to submit to and
                    successfully pass a Motor Vehicle Record (MVR) Review to attain a driver’s rating and once
                    every two years thereafter to maintain their rating.</p>
                           
                <ul class="listitem">
                <li>
                    <h6 class="mt-3"><strong>Requirements</strong></h6>                    
                <ul class="listitem">
                    <li>Be at least 16 years of age and a current Active member of USA Water Ski &amp; Wake
                                            Sports <h class="text-danger">(Guest, Grassroots, and Supporting members are <h style="text-decoration:underline;">not</h> eligible)</h></li>
                </ul></li>
                <li><h6 class="mt-3 "><strong>Renewal</strong></h6>
                    <ul class="listitem">
                        <li>Once every two years; USA Water Ski &amp; Wake Sports <a href="mailto:officials@usawaterski.org">(officials@usawaterski.org)</a> will
                            send you an email notification when your MVR Review is due for renewal. Be sure your email address is current in your member profile.</li>
                    </ul>
                </li>

                <li><h6 class="mt-3"><strong>Instructions</strong></h6>
                    <ul class="listitem">
                        <li>Click on the link below to purchase your MVR Review and follow the applicable instructions to acknowledge disqualification criteria and complete your application.
                          For Canadian MVR Reviews, email <a href="maito:officials@usawaterski.org" class="text-primary" style="text-decoration:underline;"> usawaterski@usawaterski.org</a> for a custom link to complete your application.</li>
                          <li>The screening is conducted by USA Water Ski & Wake Sports screening agency, National Center for Safety Initiatives (NCIS). Screening can take up to 7-10 business days to complete.</li>
                           <li>USA Water Ski &amp; Wake Sports <a href="maito:officials@usawaterski.org">(officials@usawaterski.org)</a> will notify you via email of
                                your pass/fail status</li>
                    </ul>
                </li>
                </ul>
                    <div class="row">
                        <div class="col-md-6">
                            <h4>Cost - $15</h4>
                        </div>
                        <div class="col-md-6">
                            <a class="btn btn-primary mt-0" @click="openLink('MVRReviewlogin_link')" :href=" MVRReviewlogin_link?'/member/mvr-review':'javascript:void(0)'" :target="MVRReviewlogin_link?'_blank':''"  >Purchase MVR Review</a>
                        </div>
            </div>
            </div>
        </div>
        <div class="col-md-4 status_bar mt-4">
            <div class="right-sidebarlink">
                  <pagesidebar :links="this.getSiteProps('memberdashboard.links')"></pagesidebar>
            </div>

        </div>
    </div>
</div>

</template>
<script>

import pagesidebar from "../components/PageSidebar";
import CustomAlert from "../../../CustomAlert";
import moment from "moment";
import $ from "jquery";
export default {

    name: "drivers-program",
     components: {CustomAlert,pagesidebar},
          data() {
    return {
        prop_links: null,
      login_link: false,
      Driverlogin_link: false,
      MVRReviewlogin_link: false,
      isMember: [],
      alertMessage:'',
      displayAlert:'',
    }
  },
  updated() {
    this.prop_links = this.getSiteProps("memberdashboard.links");
  },
  methods: {
    openLink(linkKey) {
      this.displayAlert = false;
      this.isMember = JSON.parse(localStorage.getItem("member"));
      const validThru = moment(this.isMember.membership_endtate).format('MM/DD/YYYY');
      const currentDate = moment().format('MM/DD/YYYY');
      const isExpired = moment(currentDate).isAfter(validThru);
      const age = this.isMember.age;
      const linkConfig = {
        login_link: { ageRequirement: 18, message: 'Be at least 18 years of age and a current Active member' +
              '<br> of USA Water Ski & Wake Sports (Guest, GrassRoots, and Supporting Members are not eligible)' },
        Driverlogin_link: { ageRequirement: 16, message: 'Be at least 16 years of age and a current Active member ' +
              '<br>of USA Water Ski & Wake Sports (Guest, GrassRoots, and Supporting Members are not eligible)' },
        MVRReviewlogin_link: { ageRequirement: 16, message: 'Be at least 16 years of age and a current Active member ' +
              '<br>of USA Water Ski & Wake Sports (Guest, GrassRoots, and Supporting Members are not eligible)'},
      };

      const link = linkConfig[linkKey];
      if (!isExpired && age >= link.ageRequirement && this.isMember.reg_membership_id !=7 && this.isMember.reg_membership_id !=3 && this.isMember.reg_membership_id !=4) {
        this[linkKey] = true;
      } else {
        this[linkKey] = false;
        this.alertMessage = link.message;
        this.setDangerBg();
        this.displayAlert = true;
      }
    },
    setDangerBg(){
      setTimeout(() => {
        $('#alert_box').children('div').addClass('alert-danger');
      });
    },
  },
}

</script>